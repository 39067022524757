"use strict";

var lightbox = require("lightbox2");

module.exports = {
    name: "Gallery",
    components: {},
    ready: function ready() {},
    methods: {
        getImageThumbUrl: function getImageThumbUrl(n) {
            return "../img/photos/thumb/" + (n + 1) + ".jpg";
        },
        getImageUrl: function getImageUrl(n) {
            return "../img/photos/" + (n + 1) + ".jpg";
        }
    }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "<div class=\"gallery-wrapper\"><h1>Gallery</h1><div class=\"photos row\"><div v-for=\"n in 24\" class=\"col-xs-6 col-lg-2 col-md-3 photo\"><a data-lightbox=\"prahala\" :href=\"getImageUrl(n)\"><img :src=\"getImageThumbUrl(n)\"/></a></div></div></div>"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  var id = "/Users/tomasherma/Documents/nodejs/prahala/src/js/components/gallery.vue"
  if (!module.hot.data) {
    hotAPI.createRecord(id, module.exports)
  } else {
    hotAPI.update(id, module.exports, module.exports.template)
  }
})()}