"use strict";

module.exports = {
    name: "Music",
    data: function data() {
        return {
            playlist: [{
                name: "Deny",
                filename: "Deny sample.mp3",
                info: "Voice &amp; Guitar: Beni Adam<span class='tab'></span>Bass: Miloš Klápště<span class='tab'></span>Drums: Dušan Černák"
            }, {
                name: "Drive",
                filename: "Drive sample.mp3",
                info: "Voice &amp; Guitar: Beni Adam<span class='tab'></span>Bass: Luiz<span class='tab'></span>Drums: Omar"
            }, {
                name: "Gone",
                filename: "Gone sample.mp3",
                info: "Guitar: Beni Adam<span class='tab'></span>Bass: Miloš Klápště<span class='tab'></span>Drums: Dušan Černák"
            }, {
                name: "Like Before",
                filename: "Like before.mp3",
                info: "Voice &amp; Guitar: Beni Adam<span class='tab'></span>String Quartet: Bowbreakers"
            }, {
                name: "Like Before (Jazz)",
                filename: "Like before jazz.mp3",
                info: "Guitar: Beni Adam<span class='tab'></span>Bass: Miloš Klápště<span class='tab'></span>Drums: Dušan Černák"
            }, {
                name: "Not Hungry",
                filename: "Not hungry sample.mp3",
                info: "Voice &amp; Guitar: Beni Adam<span class='tab'></span>Bass: Miloš Klápště<span class='tab'></span>Drums: Dušan Černák"
            }, {
                name: "Not Hungry (Acoustic)",
                filename: "Not hungry acoustic.mp3",
                info: "Voice &amp; Guitar: Beni Adam<span class='tab'></span>String Quartet: Hans Krasa Quartet<span class='tab'></span>Oboe: Anna Škreptáčová"
            }, {
                name: "Sleep",
                filename: "Sleep sample.mp3",
                info: "Voice &amp; Guitar: Beni Adam"
            }, {
                name: "Thin Disguise",
                filename: "Thin Disguise.mp3",
                info: "Guitar: Beni Adam<span class='tab'></span>Bass: Miloš Klápště<span class='tab'></span>Drums: Dušan Černák"
            }, {
                name: "Thin Disguise Bass Solo",
                filename: "Thin Disguise Bass Solo sample.mp3",
                info: "Bass: Miloš Klápště"
            }, {
                name: "Water",
                filename: "Water sample.mp3",
                info: "Voice &amp; Guitar: Beni Adam<span class='tab'></span>Bass: Luiz<span class='tab'></span>Drums: Omar<span class='tab'></span>Trumpet: Tommy"
            }],
            activeSong: null,
            pausedSong: null,
            audio: null
        };
    },
    components: {},
    ready: function ready() {},
    methods: {
        playAudio: function playAudio(id) {

            if (this.pausedSong == id) {
                this.audio.play();
            } else {
                if (this.activeSong != id) {
                    this.pauseAudio(id);
                }
                this.audio = new Audio("../../sounds/" + this.playlist[id].filename);
                this.audio.play();
            }
            this.activeSong = id;
        },
        pauseAudio: function pauseAudio(id) {
            this.activeSong = null;
            this.pausedSong = id;
            if (this.audio != null) {
                this.audio.pause();
            }
        }
    }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "<div class=\"music-wrapper\"><h1>Music</h1><ul class=\"playlist\"><li v-for=\"song in playlist\"><div class=\"icon-wrapper\"><div @click.prevent=\"pauseAudio($index)\" v-if=\"$index == activeSong\" class=\"icon pause\"></div><div @click.prevent=\"playAudio($index)\" v-else=\"v-else\" class=\"icon play\"></div></div><div class=\"name-wrapper\"><div class=\"name\">{{ song.name }}</div></div><div class=\"info-wrapper\"><div class=\"info\">{{{ song.info }}}                </div></div><div class=\"clearfix\"></div></li></ul><div class=\"clearfix\"></div></div>"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  var id = "/Users/tomasherma/Documents/nodejs/prahala/src/js/components/music.vue"
  if (!module.hot.data) {
    hotAPI.createRecord(id, module.exports)
  } else {
    hotAPI.update(id, module.exports, module.exports.template)
  }
})()}