global.$ = global.jQuery = require("jquery"),
    Vue = require("vue"),
    moment = require("moment"),
    jquereUI = require("jquery-ui"),
    Router = require("vue-router"),
    Resource = require("vue-resource"),
    App = require("./components/app.vue"),
    bootstrap = require("bootstrap"),
    

Vue.config.debug = true
Vue.use(Resource)
Vue.use(Router)

Vue.filter("moment", function(timestamp, format) {
    if (!format) {
        format = "DD.M.YYYY HH:mm:ss"
    }
    return moment(timestamp).format(format)
})

var router = new Router({
    linkActiveClass: "active"
})

router.map({
    "": {
        name: "main",
        component: require("./components/main.vue"),
        subRoutes: {
            "/": {
                name: "homepage",
                component: require("./components/home.vue")
            },
            "/trailer": {
                name: "trailer",
                component: require("./components/trailer.vue")
            },
            "/synopsis": {
                name: "synopsis",
                component: require("./components/synopsis.vue")
            },
            "/gallery": {
                name: "gallery",
                component: require("./components/gallery.vue")
            },
            "/music": {
                name: "music",
                component: require("./components/music.vue")
            },
            "/cast": {
                name: "cast",
                component: require("./components/cast.vue")
            }
        }
    },
    "/404": {
        component: require("./components/page_404.vue")
    }
})

// Redirect to the home route if any routes are unmatched
router.redirect({
    '*': '/404'
})


router.start(App, "#app")