"use strict";

module.exports = {
    name: "Page_404",
    components: {},
    data: function data() {
        return {};
    },
    ready: function ready() {}
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "<div id=\"fullscreen-page\" class=\"shifter-page\"><div class=\"header-photo img-18 fullscreen\"><div class=\"center\"><div class=\"page-404\"><strong>404</strong><span>Page not found</span><a v-link=\"{name: 'homepage'}\" class=\"button big style-1\">go home, you're drunk</a></div></div></div></div>"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  var id = "/Users/tomasherma/Documents/nodejs/prahala/src/js/components/page_404.vue"
  if (!module.hot.data) {
    hotAPI.createRecord(id, module.exports)
  } else {
    hotAPI.update(id, module.exports, module.exports.template)
  }
})()}