"use strict";

module.exports = {
    name: "Cast",
    components: {},
    ready: function ready() {}
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "<div class=\"cast-wrapper\"><h1>Cast</h1><div class=\"names\"><div class=\"name left\"><i>beni</i> \nADAM</div><div class=\"name right\"> <i>iva</i> \nFRÜHLINGOVÁ        </div><div class=\"name left\"><i>giorgio</i> \nARCIDIACONO</div><div class=\"name right\"><i>klára</i> \nHAJDINOVÁ</div><div class=\"name left\"><i>pavel</i> \nBATĚK</div><div class=\"name right\"> <i>vica</i> \nKEREKES </div><div class=\"name left\"> <i>alasdair</i> \nBOUCH        </div><div class=\"name right\"> <i>barbora</i> \nLUKEŠOVÁ</div><div class=\"name left\"> <i>jeremi</i> \nDURAND           </div><div class=\"name right\"> <i>tatiana</i> \nPAUHOFOVÁ</div><div class=\"name left\"><i>laco</i> \nHUDEC</div><div class=\"name right\"><i>jana</i> \nPIDRMANOVÁ   </div><div class=\"name left\"><i>ted</i> \nOTIS   </div><div class=\"name right\"> <i>jana</i> \nSTRYKOVÁ       </div><div class=\"name left\"> <i>lawrence</i> \nSHELDON</div><div class=\"name right\"><i>šárka</i> \nVACULÍKOVÁ       </div><div class=\"name left\"> <i>aleš</i> \nUHLÍK        </div><div class=\"name right\"> <i>hana</i> \nVAGNEROVÁ </div></div><div class=\"clearfix\">       </div></div>"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  var id = "/Users/tomasherma/Documents/nodejs/prahala/src/js/components/cast.vue"
  if (!module.hot.data) {
    hotAPI.createRecord(id, module.exports)
  } else {
    hotAPI.update(id, module.exports, module.exports.template)
  }
})()}