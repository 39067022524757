"use strict";

module.exports = {
    name: "Synopsis",
    components: {},
    ready: function ready() {}
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "<div class=\"synopsis-wrapper container\"><h1>Synopsis</h1><p>A historian meets and dates a local hairdresser. But local cynicism and prejudice taint their relationship, forcing them both to make difficult life decisions. This frames a magical backdrop to humorous situations and insightful observations, the complexities of contemporary relationships, and the influence of negative cultural stereotypes. The historian’s consolation is his singing and songwriting, as he plans a concert to win the hairdresser back. In the process, he confronts his own cultural and emotional contradictions. The film combines urban beauty, an honest treatment of provocative issues, and a compelling and original music soundtrack that speaks from the heart.</p></div>"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  var id = "/Users/tomasherma/Documents/nodejs/prahala/src/js/components/synopsis.vue"
  if (!module.hot.data) {
    hotAPI.createRecord(id, module.exports)
  } else {
    hotAPI.update(id, module.exports, module.exports.template)
  }
})()}