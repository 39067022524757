"use strict";

var youtubeVideo = require("videojs-youtube"),
    videojs = require("video.js");

module.exports = {
    name: "Trailer",
    data: function data() {
        return {
            video: null
        };
    },
    components: {},
    ready: function ready() {
        /*if(this.video == null) {
            this.video = videojs("trailer", {
                error: function(error) {
                    console.log(error)
                }
            })
        }*/

    },
    methods: {
        playVideo: function playVideo() {
            $(".video-image").hide();
            $("#trailer").removeClass("hidden");
        }
    }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "<div class=\"video-wrapper container\"><h1>Film Trailer</h1><div id=\"video\"><iframe id=\"trailer\" width=\"436\" height=\"242\" src=\"https://www.youtube.com/embed/ecbRh3PI1e8?rel=0&amp;amp;showinfo=0&amp;amp;vq=hd1080\" frameborder=\"0\" allowfullscreen=\"allowfullscreen\"></iframe></div><div class=\"contacts\"><h2>Contacts</h2><div class=\"name\">Beni Adam</div><span class=\"position\">Director/Producer</span><a href=\"mailto:info@prahala.com\" class=\"mail\">info@prahala.com</a></div></div>"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  var id = "/Users/tomasherma/Documents/nodejs/prahala/src/js/components/trailer.vue"
  if (!module.hot.data) {
    hotAPI.createRecord(id, module.exports)
  } else {
    hotAPI.update(id, module.exports, module.exports.template)
  }
})()}