"use strict";

module.exports = {
    name: "Main",
    components: {},
    data: function data() {
        return {
            backgroundClass: ""
        };
    },
    ready: function ready() {
        var navMain = $("#main-navigation");
        navMain.on("click", "a", null, function () {
            navMain.collapse('hide');
        });
    },
    route: {
        data: function data(req) {
            $("body").removeClass().addClass(req.to.name);
        }
    }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "<div class=\"wrapper\"><nav class=\"navbar navbar-default\"><div class=\"container\"><div class=\"navbar-header\"><button type=\"button\" data-toggle=\"collapse\" data-target=\"#main-navigation\" aria-expanded=\"false\" class=\"navbar-toggle collapsed\"><span class=\"sr-only\">Toggle navigation</span><span class=\"icon-bar\"></span><span class=\"icon-bar\"></span><span class=\"icon-bar\"></span></button></div><div id=\"main-navigation\" class=\"collapse navbar-collapse\"><ul class=\"nav navbar-nav\"><li v-link=\"{name: 'homepage', exact: true}\"><a v-link=\"{name: 'homepage', exact: true}\">Home</a></li><li v-link=\"{name: 'trailer'}\"><a v-link=\"{name: 'trailer'}\">Trailer</a></li><li v-link=\"{name: 'synopsis'}\"><a v-link=\"{name: 'synopsis'}\">Synopsis</a></li><li v-link=\"{name: 'gallery'}\"><a v-link=\"{name: 'gallery'}\">Gallery</a></li><li v-link=\"{name: 'music'}\"><a v-link=\"{name: 'music'}\">Music</a></li><li v-link=\"{name: 'cast'}\"><a v-link=\"{name: 'cast'}\">Cast</a></li></ul></div></div></nav><div id=\"main\" class=\"container\"><router-view></router-view></div><div class=\"footer\"><div class=\"container\"><div class=\"icons\"><a href=\"https://www.facebook.com/prahala2017/\" target=\"_blank\" class=\"icon facebook\"></a><a href=\"\" class=\"icon google\"></a><a href=\"https://www.youtube.com/watch?v=e0DX7EkvX3g\" target=\"_blank\" class=\"icon youtube\"></a></div><div class=\"copyright\">© Beni Adam 2016. All Rights Reserved</div></div></div></div>"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  var id = "/Users/tomasherma/Documents/nodejs/prahala/src/js/components/main.vue"
  if (!module.hot.data) {
    hotAPI.createRecord(id, module.exports)
  } else {
    hotAPI.update(id, module.exports, module.exports.template)
  }
})()}