"use strict";

module.exports = {
    name: "Homepage",
    components: {},
    ready: function ready() {}
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "<div class=\"wrapper\"><div class=\"perex\"><div class=\"text\"><div class=\"line\"> \nA\n <em>c</em>ity</div><div class=\"line\"> \nA\n <em>h</em>airdresser</div><div class=\"line\"> \nA \n <em>h</em>istorian</div><div class=\"line\"> \nI<em>dentity         </em></div><div class=\"line\"> \nI<em>llusion            </em></div><div class=\"line\"> \nI<em>nspiration</em></div></div></div></div>"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  var id = "/Users/tomasherma/Documents/nodejs/prahala/src/js/components/home.vue"
  if (!module.hot.data) {
    hotAPI.createRecord(id, module.exports)
  } else {
    hotAPI.update(id, module.exports, module.exports.template)
  }
})()}